import { type ReactNode } from 'react';

import { UGCDiffNewValue, UGCDiffOldValue } from './UGCDiffModal';

export type DiffItem = {
  elements: {
    currText: string;
    preText?: string;
    change?: 'added' | 'removed' | 'updated';
  }[];
};

export function UGCDiffTable(props: {
  headers: string[];
  items: DiffItem[];
  onClick?: (index: number) => void;
}) {
  const { items } = props;
  const cols = props.headers.length;

  return (
    <table className='text-white text-sms w-full text-center border border-secondary rounded-xl'>
      <thead>
        <tr className='h-10'>
          {props.headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          const elements = item.elements.slice(0, cols);
          if (elements.length < cols) {
            elements.push(
              ...new Array(cols - elements.length).fill({ currText: '' })
            );
          }
          return (
            <tr
              className={`h-10 hover:bg-lp-gray-002 odd:bg-lp-gray-001 ${
                props.onClick ? 'cursor-pointer' : undefined
              }`}
              onClick={() => props.onClick?.(index)}
              key={index}
            >
              {elements.map((el, index) => (
                <td key={index}>
                  {el.change === 'added' && (
                    <UGCDiffNewValue value={el.currText} />
                  )}
                  {el.change === 'removed' && (
                    <UGCDiffOldValue value={el.preText ?? ''} />
                  )}
                  {el.change === 'updated' && (
                    <>
                      <UGCDiffOldValue value={el.preText ?? ''} />
                      <UGCDiffNewValue value={el.currText} />
                    </>
                  )}
                  {!el.change && <div>{el.currText}</div>}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export function UGCDiffTableModal(props: {
  children: ReactNode;
  footer?: ReactNode;
}) {
  return (
    <div className='w-screen h-screen z-50 fixed inset-0 flex items-center justify-center'>
      <section className='w-4/5 h-full flex flex-col items-center justify-center gap-5 p-8'>
        <div className='w-full h-4/5 overflow-y-auto bg-black'>
          {props.children}
        </div>
        {props.footer}
      </section>
    </div>
  );
}
