import { useMemo } from 'react';

import { type IcebreakerCard } from '@lp-lib/game';

import {
  type DiffItem,
  UGCDiffTable,
  UGCDiffTableModal,
} from '../UGCDiffTable';

function buildIcebreakerDiffCards(
  num: number,
  oldCards: IcebreakerCard[],
  newCards: IcebreakerCard[]
) {
  const len = Math.max(oldCards.length, newCards.length);
  const diffCards: DiffItem[] = [];
  for (let i = 0; i < len; i++) {
    const oldCard = oldCards[i];
    const newCard = newCards[i];
    const options: DiffItem['elements'] = [];
    for (let j = 0; j < num; j++) {
      const oldOption = oldCard?.options[j];
      const newOption = newCard?.options[j];
      if (oldOption && newOption) {
        if (oldOption.text !== newOption.text) {
          options.push({
            currText: newOption.text,
            preText: oldOption.text,
            change: 'updated',
          });
        } else {
          options.push({ currText: newOption.text });
        }
      } else if (oldOption) {
        options.push({
          currText: '',
          preText: oldOption.text,
          change: 'removed',
        });
      } else if (newOption) {
        options.push({ currText: newOption.text, change: 'added' });
      }
    }
    diffCards.push({ elements: options });
  }
  return diffCards;
}

export function IcebreakerDiffTableModal(props: {
  headers: string[];
  oldCards: IcebreakerCard[];
  newCards: IcebreakerCard[];
  onClick?: (index: number) => void;
  footer?: React.ReactNode;
}) {
  const diffCards = useMemo(
    () =>
      buildIcebreakerDiffCards(
        props.headers.length,
        props.oldCards,
        props.newCards
      ),
    [props.headers.length, props.oldCards, props.newCards]
  );
  return (
    <UGCDiffTableModal footer={props.footer}>
      <UGCDiffTable
        headers={props.headers}
        items={diffCards}
        onClick={props.onClick}
      />
    </UGCDiffTableModal>
  );
}
