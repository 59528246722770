import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';
import { type IcebreakerBlock } from '@lp-lib/game';

import { fromMediaDTO } from '../../../../../utils/api-dto';
import {
  ImagePickPriorityHighToLow,
  MediaUtils,
} from '../../../../../utils/media';
import { type ActivityEditorProps } from '../../types';

export function IcebreakerActivityEditorLayout(
  props: ActivityEditorProps<IcebreakerBlock> & {
    children?: React.ReactNode;
    accessory?: React.ReactNode;
    hint?: string;
  }
) {
  const url = MediaUtils.PickMediaUrl(fromMediaDTO(props.asset.media), {
    priority: ImagePickPriorityHighToLow,
  });
  return (
    <div
      className='w-full relative flex items-end justify-center'
      style={{
        backgroundImage: url ? `url(${url})` : undefined,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        aspectRatio: '16/9',
      }}
    >
      <div className='flex w-4/5 h-2/3 pb-10'>
        <div className='flex flex-col w-50 h-full relative transform translate-x-5'>
          <div className='w-full h-full bg-white rounded-b-xl rounded-t-2.5xl absolute'></div>
          <div className='absolute w-full'>
            <img
              className='w-full rounded-2.5xl'
              src={getStaticAssetPath('images/taboo-onstage-player.png')}
              alt='Onstage Player'
              style={{
                aspectRatio: '1/1',
                boxShadow: '0px 1.76px 7.04px 0px rgba(0, 0, 0, 0.40)',
              }}
            />
            <div className='text-black text-2xs font-bold mt-5 mx-3.5'>
              {props.hint}
            </div>
          </div>
        </div>
        <div
          className='flex-1 rounded-2.5xl border border-white border-opacity-20 
          bg-white bg-opacity-20 transform scale-95 -translate-x-5 
          flex items-center justify-center relative'
        >
          {props.children}
          {props.accessory}
        </div>
      </div>
    </div>
  );
}
