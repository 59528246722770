import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import useMeasure from 'react-use-measure';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../icons/Arrows';

export type OrdinalItem = {
  id: string;
  label?: string;
  unread?: boolean;
};

const itemUnitPx = 40;
const itemGapPx = 4;
const itemWidthPx = itemUnitPx + itemGapPx;

function OrginalButton(props: {
  item: OrdinalItem;
  index: number;
  selected: boolean;
  onClick: () => void;
  contextMenu?: (id: string, index: number) => JSX.Element;
}) {
  const { item, index } = props;

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'bottom-start',
      trigger: props.contextMenu ? 'right-click' : null,
      interactive: true,
      delayHide: 150,
    });

  return (
    <div>
      <button
        ref={setTriggerRef}
        key={item.id}
        type='button'
        className={`btn-secondary rounded-lg w-10 h-8 flex 
                  items-center justify-center font-bold flex-shrink-0 
                  ${props.selected ? 'border-white' : ''}`}
        onClick={() => props.onClick()}
        title={item.label}
      >
        {index + 1}
      </button>
      {visible &&
        props.contextMenu &&
        createPortal(
          <div ref={setTooltipRef} {...getTooltipProps()}>
            {props.contextMenu(item.id, index)}
          </div>,
          document.body
        )}
    </div>
  );
}

export function OrdinalCarousel(props: {
  items: OrdinalItem[];
  onSelect: (id: string, index: number) => void;
  selected?: { id: string; index: number };
  contextMenu?: (id: string, index: number) => JSX.Element;
}) {
  const { items } = props;
  const itemListRef = useRef<HTMLDivElement | null>(null);
  const [ref, bounds] = useMeasure({
    debounce: 100,
    polyfill: ResizeObserver,
  });
  const [leftIndex, setLeftIndex] = useState(0);
  // there is no gap at the beginning and end, so we need to add 4px
  const visibleUnitsCount = Math.floor(
    (bounds.width + itemGapPx) / itemWidthPx
  );

  useEffect(() => {
    if (!itemListRef.current) return;

    itemListRef.current.children[leftIndex]?.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
    });
  }, [leftIndex]);

  const handleSetLeftIndex = useLiveCallback((index: number) => {
    if (index + visibleUnitsCount > items.length - 1)
      index = items.length - visibleUnitsCount;
    if (index < 0) index = 0;
    setLeftIndex(index);
  });

  const handleBack = () => {
    handleSetLeftIndex(leftIndex - visibleUnitsCount);
  };

  const handleForward = () => {
    handleSetLeftIndex(leftIndex + visibleUnitsCount);
  };

  useEffect(() => {
    handleSetLeftIndex(props.selected?.index ?? 0);
  }, [handleSetLeftIndex, props.selected]);

  return (
    <div className='flex-1 overflow-hidden flex relative items-center'>
      <button
        type='button'
        className='btn w-10 h-8 flex items-center justify-center flex-shrink-0 absolute left-0 z-5'
        onClick={handleBack}
        disabled={leftIndex === 0}
      >
        <ArrowLeftIcon />
      </button>
      <div className='max-w-full w-[fit-content] flex items-center gap-1 relative'>
        <div className='w-10 h-8 flex-none'></div>
        <div className='flex-1 overflow-x-hidden' ref={ref}>
          <div
            ref={itemListRef}
            className='w-full flex items-center overflow-x-scroll scrollbar-hide gap-1'
            style={{ scrollSnapType: 'x mandatory' }}
          >
            {props.items.map((item, index) => (
              <OrginalButton
                key={item.id}
                item={item}
                index={index}
                onClick={() => props.onSelect(item.id, index)}
                selected={item.id === props.selected?.id}
                contextMenu={props.contextMenu}
              />
            ))}
          </div>
        </div>
        <div className='w-10 h-8 flex-none'></div>
      </div>
      <button
        type='button'
        className='btn w-10 h-8 flex items-center justify-center flex-shrink-0 absolute right-0 z-5'
        onClick={handleForward}
        disabled={leftIndex + visibleUnitsCount >= items.length}
      >
        <ArrowRightIcon />
      </button>
    </div>
  );
}
