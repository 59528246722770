import { FileInput } from '@uppy/react';
import { Fragment, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import {
  BlockType,
  type DrawingPrompt,
  type DrawingPromptBlock,
} from '@lp-lib/game';
import { drawingBlankGameSchema } from '@lp-lib/shared-schema/src/ai/functions/zod/drawingBlankGame';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { apiService } from '../../../../services/api-service';
import { fromDTOBlocks, fromMediaDTO } from '../../../../utils/api-dto';
import { downloadFile, uuidv4 } from '../../../../utils/common';
import { createCSVFile, csvToArray } from '../../../../utils/csv';
import {
  ImagePickPriorityHighToLow,
  MediaUtils,
} from '../../../../utils/media';
import { MenuItem } from '../../../common/ActionMenu';
import { useCancelConfirmModalStateRoot } from '../../../ConfirmCancelModalContext';
import {
  useCSVUploader,
  useEditor,
} from '../../Blocks/Common/Editor/EditorUtilities';
import { DrawingToolkit } from '../../Blocks/DrawingPrompt/DrawingBoard';
import { UGCActivityPromptEditor } from '../CustomGamePackPromptEditor';
import { OrdinalCarousel } from '../design/OrdinalCarousel';
import { SimpleTextEditor } from '../design/SimpleTextEditor';
import {
  UGCDiffAcceptButton,
  UGCDiffRejectButton,
} from '../design/UGCDiffModal';
import {
  type DiffItem,
  UGCDiffTable,
  UGCDiffTableModal,
} from '../design/UGCDiffTable';
import { UGCEditorToolMenu } from '../design/UGCEditorToolMenu';
import {
  type ActivityEditorProps,
  type ActivityGenAIRunContext,
  type ActivityGenAIRunRequest,
  type BlockId,
} from '../types';
import { generateBlock, log, UGCUtils, useAbortableBlockGenAI } from '../utils';

const parseSchema = drawingBlankGameSchema.parse;

type BlockContext = {
  prompts: string[];
};

function validBlockContext(val: unknown): val is BlockContext {
  return (
    typeof val === 'object' &&
    val !== null &&
    'prompts' in val &&
    Array.isArray(val['prompts'])
  );
}

async function buildBlockContextFromPack(
  packId: string,
  currBlockId: string
): Promise<ActivityGenAIRunContext> {
  const resp = await apiService.gamePack.getGamePackById(packId, {
    blocks: true,
  });
  const blocks = fromDTOBlocks(resp.data.blocks) ?? [];
  const drawingBlocks = blocks.filter(
    (b): b is DrawingPromptBlock =>
      b.type === BlockType.DRAWING_PROMPT && b.id !== currBlockId
  );
  const ctx: ActivityGenAIRunContext = {};
  for (const block of drawingBlocks) {
    ctx[block.id as BlockId] = {
      prompts: block.fields.prompts.map((p) => p.correct),
    };
  }
  return ctx;
}

function transform(data: ReturnType<typeof parseSchema>) {
  return data.prompts.map((p) => ({
    id: uuidv4(),
    correct: p,
  }));
}

async function runAI(
  req: ActivityGenAIRunRequest<DrawingPromptBlock>
): Promise<ReturnType<typeof parseSchema>> {
  const { packId, block, prompt, signal } = req;
  const ctx = req.ctx ?? {};
  const excludePrompts: string[] = [];
  for (const val of Object.values(ctx)) {
    if (!validBlockContext(val)) continue;
    excludePrompts.push(...val.prompts);
  }
  const currentPrompts = block.fields.prompts.map((p) => p.correct);

  const variables = {
    excludePrompts: JSON.stringify(excludePrompts),
    currentPrompts: JSON.stringify(currentPrompts),
  };
  const resp = await generateBlock(
    packId,
    {
      blockId: req.block.id,
      userPrompt: prompt,
      variables,
    },
    { signal }
  );
  log.info('generate block response', { data: resp.data });

  for (const step of resp.data.runSteps) {
    if (UGCUtils.VaildRunStep(step)) {
      const fn = step.stepDetails.tool_calls[0].function;
      const output = UGCUtils.ParseFunctionCall(packId, block, fn, parseSchema);
      return output;
    }
  }
  throw new Error('Something went wrong, pelase try again');
}

export async function drawingPromptGenAIAction(
  req: ActivityGenAIRunRequest<DrawingPromptBlock>
): Promise<ActivityGenAIRunContext> {
  const data = await runAI(req);
  await apiService.block.updateFieldV2(req.block, 'prompts', transform(data));

  return {
    [req.block.id]: {
      prompts: data.prompts,
    },
  };
}

function buildDiffPrompts(
  oldPrompts: DrawingPrompt[],
  newPrompts: DrawingPrompt[]
) {
  const len = Math.max(oldPrompts.length, newPrompts.length);
  const diffItems: DiffItem[] = [];
  for (let i = 0; i < len; i++) {
    const oldPrompt = oldPrompts[i];
    const newPrompt = newPrompts[i];
    const elements: DiffItem['elements'] = [];
    if (oldPrompt && newPrompt) {
      if (oldPrompt.correct !== newPrompt.correct) {
        elements.push({
          currText: newPrompt.correct,
          preText: oldPrompt.correct,
          change: 'updated',
        });
      } else {
        elements.push({ currText: newPrompt.correct });
      }
    } else if (oldPrompt) {
      elements.push({
        currText: '',
        preText: oldPrompt.correct,
        change: 'removed',
      });
    } else if (newPrompt) {
      elements.push({ currText: newPrompt.correct, change: 'added' });
    }
    diffItems.push({ elements });
  }
  return diffItems;
}

function DiffTableModal(props: {
  oldPrompts: DrawingPrompt[];
  newPrompts: DrawingPrompt[];
  onClick?: (index: number) => void;
  footer?: React.ReactNode;
}) {
  const headers = useMemo(() => ['Drawing Prompt'], []);
  const diffItems = useMemo(
    () => buildDiffPrompts(props.oldPrompts, props.newPrompts),
    [props.oldPrompts, props.newPrompts]
  );
  return (
    <UGCDiffTableModal footer={props.footer}>
      <UGCDiffTable
        headers={headers}
        items={diffItems}
        onClick={props.onClick}
      />
    </UGCDiffTableModal>
  );
}

export function DrawingBlankActivityEditor(
  props: ActivityEditorProps<DrawingPromptBlock>
): JSX.Element | null {
  const { packId, block, asset, root } = props;
  const { updateField } = useEditor(props);
  const [modal, triggerModal] = useCancelConfirmModalStateRoot();

  const prompts = block.fields.prompts;
  const [selected, setSelected] = useState<{
    id: string;
    index: number;
  } | null>(
    prompts.length
      ? {
          id: prompts[0].id,
          index: 0,
        }
      : null
  );
  const selectedPrompt = selected ? prompts[selected.index] : null;
  const onChange = async (prompts: DrawingPrompt[]) => {
    await updateField('prompts', prompts);
  };
  const handleAddPrompt = async () => {
    const length = prompts.length;
    const newPrompt = {
      id: uuidv4(),
      correct: '',
    };
    await onChange([...prompts, newPrompt]);
    setSelected({ id: newPrompt.id, index: length });
  };

  const handleDeletePrompt = async (promptId: string) => {
    const idx = prompts.findIndex((c) => c.id === promptId);
    if (idx === -1) return;
    if (selected?.id === promptId) {
      setSelected(null);
    }
    await onChange(prompts.filter((c) => c.id !== promptId));
    if (selected?.id === promptId) {
      if (prompts[idx + 1]) {
        setSelected({ id: prompts[idx + 1].id, index: idx + 1 });
      } else if (prompts[idx - 1]) {
        setSelected({ id: prompts[idx - 1].id, index: idx - 1 });
      } else if (prompts[0]) {
        setSelected({ id: prompts[0].id, index: 0 });
      } else {
        setSelected(null);
      }
    }
  };

  const handleUpdatePrompt = useLiveCallback((prompt: DrawingPrompt) => {
    const newPrompts = prompts.map((c) => (c.id === prompt.id ? prompt : c));
    onChange(newPrompts);
  });

  const { generate, abort, state, error } = useAbortableBlockGenAI(
    async (prompt: string, signal: AbortSignal) => {
      const originalPrompt = prompt;
      let optimizedPrompt = prompt;

      try {
        const optimizePromptResp = await apiService.gamePack.optimizeUGCPrompt(
          packId,
          {
            prompt,
          }
        );
        optimizedPrompt = optimizePromptResp.data.prompt;
      } catch (error) {
        log.error('Failed to optimize prompt', error);
      }

      props.analytics.trackCustomGameAIPrompt({
        packId: packId,
        blockId: block.id,
        blockType: block.type,
        prompt: originalPrompt,
        optimizedPrompt:
          originalPrompt === optimizedPrompt ? 'N/A' : optimizedPrompt,
      });

      const data = await runAI({
        packId,
        block,
        prompt: optimizedPrompt,
        signal,
        ctx: await buildBlockContextFromPack(packId, block.id),
      });
      const newPrompts = transform(data);

      await triggerModal({
        kind: 'custom',
        element: (p) => (
          <DiffTableModal
            oldPrompts={block.fields.prompts}
            newPrompts={newPrompts}
            footer={
              <footer className='flex justify-center items-center gap-5'>
                <UGCDiffRejectButton onClick={p.internalOnCancel} />
                <UGCDiffAcceptButton
                  onClick={async () => {
                    await updateField('prompts', newPrompts);
                    p.internalOnConfirm();
                    if (prompts.length > 0) {
                      setSelected({ id: newPrompts[0].id, index: 0 });
                    } else {
                      setSelected(null);
                    }
                  }}
                />
              </footer>
            }
          />
        ),
      });

      return true;
    }
  );

  const items = useMemo(() => {
    return prompts.map((p) => ({
      id: p.id,
      label: p.correct,
    }));
  }, [prompts]);

  const handleSeeAll = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <DiffTableModal
          oldPrompts={block.fields.prompts}
          newPrompts={block.fields.prompts}
          onClick={(index) => {
            setSelected({ id: prompts[index].id, index });
            p.internalOnCancel();
          }}
          footer={
            <footer className='flex justify-center items-center gap-5'>
              <button
                type='button'
                onClick={p.internalOnCancel}
                className={`btn-primary w-26 h-9`}
              >
                <p>Close</p>
              </button>
            </footer>
          }
        />
      ),
    });
  };

  const uppy = useCSVUploader(async (content) => {
    const csv = csvToArray(content.toString());
    if (csv.length <= 1) return;
    const rows = csv.splice(1);
    const newPrompts = rows.map<DrawingPrompt>((row) => {
      return {
        id: uuidv4(),
        correct: row[0] ?? '',
      };
    });
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <DiffTableModal
          oldPrompts={block.fields.prompts}
          newPrompts={newPrompts}
          footer={
            <footer className='flex justify-center items-center gap-5'>
              <UGCDiffRejectButton onClick={p.internalOnCancel} />
              <UGCDiffAcceptButton
                onClick={async () => {
                  await updateField('prompts', newPrompts);
                  p.internalOnConfirm();
                  if (prompts.length > 0) {
                    setSelected({ id: newPrompts[0].id, index: 0 });
                  } else {
                    setSelected(null);
                  }
                }}
              />
            </footer>
          }
        />
      ),
    });
  });

  const handleCSVDownload = () => {
    const data = [['Drawing Prompt']];
    for (const prompt of block.fields.prompts) {
      data.push([prompt.correct]);
    }
    const url = createCSVFile(data);
    downloadFile(url, `drawing-the-blank-${block.id}.csv`);
  };

  const url = MediaUtils.PickMediaUrl(fromMediaDTO(asset.media), {
    priority: ImagePickPriorityHighToLow,
  });

  const themeColor = '#FF3975';

  return (
    <Fragment>
      {modal && root?.current && createPortal(modal, root.current)}
      <div
        className={`w-full h-full flex-col ${
          modal ? 'hidden' : 'flex'
        } gap-0.5`}
      >
        <div className='w-full absolute flex flex-col gap-2'>
          <div className='w-full flex text-white items-center gap-1'>
            <div className='text-sms font-bold flex-shrink-0 text-center'>
              <p>Preview Prompts ({prompts.length})</p>
            </div>
            <OrdinalCarousel
              items={items}
              onSelect={(id, index) => setSelected({ id, index })}
              selected={selected ?? undefined}
              contextMenu={(id, index) => (
                <button
                  type='button'
                  className='btn bg-secondary text-red-002 
              text-opacity-80 hover:text-opacity-100 w-20 h-8 text-sms'
                  onClick={() => handleDeletePrompt(id)}
                >
                  Delete #{index + 1}
                </button>
              )}
            />
            <button
              type='button'
              className='btn-secondary h-8 flex-shrink-0 font-bold 
          flex items-center justify-center px-4 rounded-lg'
              onClick={handleAddPrompt}
            >
              Add Prompt
            </button>
            <UGCEditorToolMenu>
              {(hide) => (
                <Fragment>
                  <MenuItem
                    text='See All'
                    onClick={() => {
                      handleSeeAll();
                      hide();
                    }}
                  />
                  <MenuItem
                    text={
                      <label className='w-full h-full cursor-pointer flex items-center justify-start'>
                        Upload CSV
                        <div className='hidden'>
                          <FileInput uppy={uppy} inputName={'files'} />
                        </div>
                      </label>
                    }
                    onClick={() => {
                      setTimeout(() => hide(), 1000);
                    }}
                  />
                  <MenuItem
                    text='Download CSV'
                    onClick={() => {
                      handleCSVDownload();
                      hide();
                    }}
                  />
                </Fragment>
              )}
            </UGCEditorToolMenu>
          </div>
          <div
            className='w-full relative flex items-end justify-center'
            style={{
              backgroundImage: url ? `url(${url})` : undefined,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              aspectRatio: '16/9',
            }}
          >
            {selectedPrompt ? (
              <div className='flex items-start justify-center gap-4 w-full mb-10'>
                <div
                  key={selectedPrompt.id}
                  className='w-1/3'
                  style={{ aspectRatio: '5/4' }}
                >
                  <SimpleTextEditor
                    label='Do your best to draw:'
                    value={selectedPrompt.correct}
                    onBlur={(v) => {
                      handleUpdatePrompt({
                        ...selectedPrompt,
                        correct: v.replace(/\n/g, ' '),
                      });
                    }}
                    placeholder='Enter the prompt'
                    containerClassName='bg-opacity-80 !p-8 text-center text-xl'
                    textClassName='bg-transparent text-white border-primary 
                    text-xl text-tertiary'
                  />
                </div>
                <div className='relative flex items-start w-1/3'>
                  <div
                    className='w-full bg-white rounded'
                    style={{
                      aspectRatio: '1/1',
                      boxShadow: '6px 8px 11px rgba(0, 0, 0, 0.66)',
                    }}
                  ></div>
                  <DrawingToolkit
                    themeColor={themeColor}
                    currColor={themeColor}
                    onChangeColor={() => void 0}
                    onUndo={() => void 0}
                    undoable
                  />
                </div>
              </div>
            ) : (
              <div>Select a prompt first</div>
            )}
          </div>
        </div>
        <UGCActivityPromptEditor
          enabled={!!props.enableAI}
          onSubmit={generate}
          onAbort={abort}
          isSubmitting={state.isRunning}
          ctxLabel={`Editing ${asset.primaryText}`}
          error={error}
          enableThinkingHint
          placeholder='Generate 20 drawing prompts of ...'
        />
      </div>
    </Fragment>
  );
}
