import { FileInput } from '@uppy/react';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { usePrevious } from 'react-use';

import {
  type DtoGamePackUGCFile,
  EnumsGamePackUGCFileStatus,
} from '@lp-lib/api-service-client/public';
import { type Media, MediaType } from '@lp-lib/media';

import { useInstance } from '../../../hooks/useInstance';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { fromMediaDTO } from '../../../utils/api-dto';
import { BrowserTimeoutCtrl } from '../../../utils/BrowserTimeoutCtrl';
import { Chan } from '../../../utils/Chan';
import { assertExhaustive, err2s } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import { AIIcon } from '../../icons/AIIcon';
import { AttachIcon } from '../../icons/AttachIcon';
import { ChatSendIcon } from '../../icons/Chat/ChatSendIcon';
import { ErrorIcon } from '../../icons/ErrorIcon';
import { NewWindowIcon } from '../../icons/NewWindowIcon';
import { StopIcon } from '../../icons/StopIcon';
import { TimerIcon } from '../../icons/TimerIcon';
import { XIcon } from '../../icons/XIcon';
import { Loading } from '../../Loading';
import {
  useIsUGCFileManagerDraging,
  useUGCFileManager,
  useUGCFiles,
} from './CustomGameFileManager';
import { CustomGameThinkingHint } from './CustomGameThinkingHint';
import {
  type LocalUGCFile,
  type Message,
  type RemoteUGCFile,
  type UGCFile,
} from './types';
import { useModifierKeySymbol } from './utils';

function SimpleFilePreview(props: { fileName: string }) {
  return (
    <div className='w-full h-full bg-[#8C6FFF] flex items-center justify-center p-1 rounded-lg'>
      <p className='text-3xs text-white font-bold truncate'>{props.fileName}</p>
    </div>
  );
}

function MediaPreview(props: { fileName: string; media: Media }) {
  const { fileName, media } = props;

  switch (media.type) {
    case MediaType.Image:
      return (
        <img
          src={MediaUtils.PickMediaUrl(media) || ''}
          alt='preview'
          className='w-full h-full rounded-lg object-contain'
        />
      );
    case MediaType.Video:
      return (
        <video
          src={media.url}
          className='w-full h-full rounded-lg object-contain'
          preload={'none'}
          poster={media.firstThumbnailUrl ?? ''}
        />
      );
    case MediaType.Audio:
      return <SimpleFilePreview fileName={fileName} />;
    default:
      assertExhaustive(media.type);
      return null;
  }
}

function LocalFileIcon(props: {
  status: LocalUGCFile['status'];
  className?: string;
}) {
  switch (props.status) {
    case 'none':
      return <TimerIcon className={`${props.className} fill-current`} />;
    case 'uploading':
      return <Loading text='' imgClassName={`${props.className}`} />;
    case 'failed':
      return <ErrorIcon className={`${props.className} fill-[#EE3529]`} />;
    default:
      assertExhaustive(props.status);
      return null;
  }
}

function useLocalFileLabel(status: LocalUGCFile['status']) {
  switch (status) {
    case 'none':
    case 'uploading':
      return 'Uploading';
    case 'failed':
      return `Upload failed`;
    default:
      assertExhaustive(status);
      return null;
  }
}

function LocalUGCFileAttachment(props: {
  file: LocalUGCFile;
  onDelete: () => void;
}) {
  const { status, file } = props.file;
  const label = useLocalFileLabel(status);
  return (
    <div
      className='w-25 h-14 flex-shrink-0 border border-secondary relative
      rounded-lg'
    >
      <SimpleFilePreview fileName={file.name} />
      <div className='absolute -right-1.5 -top-1.5 w-5 h-5 text-white group'>
        <LocalFileIcon
          status={status}
          className={`w-full h-full block ${
            status !== 'uploading' ? 'group-hover:hidden' : ''
          }`}
        />
        <button
          type='button'
          className={`w-full h-full bg-light-gray hidden ${
            status !== 'uploading' ? 'group-hover:flex' : ''
          } items-center justify-center text-white rounded-full`}
          onClick={props.onDelete}
        >
          <XIcon className='w-3 h-3 fill-current' />
        </button>
      </div>
      {label && (
        <div
          className='absolute bottom-0 text-white text-3xs text-center 
      w-full bg-black bg-opacity-25 py-0.5'
        >
          {label}
        </div>
      )}
    </div>
  );
}

function RemoteFileIcon(props: {
  status: DtoGamePackUGCFile['status'];
  className?: string;
}) {
  switch (props.status) {
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusPreProcessing:
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusInProgress:
      return <Loading text='' imgClassName={`${props.className}`} />;
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusFailed:
      return <ErrorIcon className={`${props.className} fill-[#EE3529]`} />;
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusCompleted:
      return null;
    default:
      assertExhaustive(props.status);
      return null;
  }
}

function useRemoteFileLabel(status: DtoGamePackUGCFile['status']) {
  switch (status) {
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusPreProcessing:
      return 'Preparing';
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusInProgress:
      return 'Analyzing';
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusFailed:
      return null;
    case EnumsGamePackUGCFileStatus.GamePackUGCFileStatusCompleted:
      return null;
    default:
      assertExhaustive(status);
      return null;
  }
}

function RemoteUGCFileAttachment(props: {
  file: RemoteUGCFile;
  onDelete: () => void;
  onDownload: () => void;
}) {
  const { file } = props.file;
  const label = useRemoteFileLabel(file.status);

  return (
    <div
      className='w-25 h-14 flex-shrink-0 border border-secondary relative
      rounded-lg group-1'
    >
      {file.media?.media ? (
        <MediaPreview
          fileName={file.name}
          media={fromMediaDTO(file.media.media)}
        />
      ) : (
        <SimpleFilePreview fileName={file.name} />
      )}
      <div
        className='absolute inset-0 w-full h-full rounded-lg bg-secondary 
      bg-opacity-40 items-center justify-center hidden group-1-hover:flex z-5'
      >
        <button
          type='button'
          className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 
        text-white'
          onClick={props.onDownload}
        >
          <NewWindowIcon className='w-5 h-5 fill-current' />
        </button>
      </div>
      <div className='absolute -right-1.5 -top-1.5 w-5 h-5 text-white group z-10'>
        <RemoteFileIcon
          status={file.status}
          className='w-full h-full block group-hover:hidden'
        />
        <button
          type='button'
          className='w-full h-full bg-light-gray hidden group-hover:flex 
          items-center justify-center text-white rounded-full'
          onClick={props.onDelete}
        >
          <XIcon className='w-3 h-3 fill-current' />
        </button>
      </div>
      {label && (
        <div
          className='absolute bottom-0 text-white text-3xs text-center 
      w-full bg-black bg-opacity-25 py-0.5'
        >
          {label}
        </div>
      )}
    </div>
  );
}

function UGCFileAttachment(props: {
  file: UGCFile;
  onDelete: () => void;
  onDownload: () => void;
}) {
  return props.file.remote ? (
    <RemoteUGCFileAttachment
      file={props.file}
      onDelete={props.onDelete}
      onDownload={props.onDownload}
    />
  ) : (
    <LocalUGCFileAttachment file={props.file} onDelete={props.onDelete} />
  );
}

type EditorProps = {
  className?: string;
  enabled: boolean;
  onSubmit: (prompt: string) => Promise<boolean>;
  onAbort: () => void;
  isSubmitting: boolean;
  active?: boolean;
  autoFocus?: boolean;
  ctxLabel?: string;
  error?: unknown;
  enableThinkingHint?: boolean;
  accessory?: ReactNode;
  placeholder?: string;
  showOverlayWhenActive?: boolean;
};

export function CustomGamePackPromptEditor(props: EditorProps) {
  const { onSubmit, isSubmitting, error } = props;
  const [prompt, setPrompt] = useState('');
  const [active, setActive] = useState(!!props.active);
  const ref = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState<Message | null>(null);
  const modSym = useModifierKeySymbol();
  const fileman = useUGCFileManager();
  const files = useUGCFiles();
  const draging = useIsUGCFileManagerDraging();
  const hasIncompleteFiles = files.some((f) =>
    f.remote ? f.file.status !== 'completed' : true
  );
  const hasInProgressFiles = files.some((f) =>
    f.remote
      ? f.file.status === 'pre_processing' || f.file.status === 'in_progress'
      : f.status === 'uploading'
  );

  const toggle = () => {
    if (active) {
      setActive(false);
    } else {
      setActive(true);
      requestAnimationFrame(() => ref.current?.focus());
    }
  };

  const submit = async () => {
    setPrompt('');
    ref.current?.blur();
    const succeeded = await onSubmit(prompt);
    if (!succeeded) {
      ref.current?.focus();
      setPrompt(prompt);
      return;
    }
    setActive(false);
  };

  const onKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) return;
      if (hasInProgressFiles) {
        chan.put({ type: 'error', detail: 'Files are not ready yet' });
        e.preventDefault();
        return;
      }
      if (prompt.trim() === '') {
        chan.put({
          type: 'success',
          detail: 'Tell us more of what you want to generate.',
        });
        e.preventDefault();
        return;
      }
      await submit();
    } else if (e.key === 'Escape') {
      setActive(false);
    }
  };

  useEffect(() => {
    if (!ref.current || !props.autoFocus) return;
    ref.current.focus();
  }, [props.autoFocus]);

  const chan = useInstance(() => new Chan<Message>());

  useEffect(() => {
    const ctrl = new BrowserTimeoutCtrl();
    async function tick() {
      const next = await chan.take();
      if (!next) return;
      setMessage(next);
      ctrl.set(() => {
        setMessage(null);
        tick();
      }, 3000);
    }
    tick();
    return () => ctrl.clear();
  }, [chan]);

  useEffect(() => {
    if (!error) return;
    chan.put({ type: 'error', detail: err2s(error) ?? '' });
  }, [chan, error]);

  useEffect(() => {
    return fileman.on('message', (msg) => {
      chan.put(msg);
    });
  }, [chan, fileman]);

  useOutsideClick(containerRef, () => {
    if (isSubmitting) return;
    setActive(false);
  });

  useHotkeys('mod+k', () => toggle(), {
    enabled: !isSubmitting,
    enableOnFormTags: ['TEXTAREA'],
  });

  const dropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!dropRef.current) return;
    return fileman.enableDrapDrop(dropRef.current);
  }, [fileman]);

  const fileListRef = useRef<HTMLDivElement>(null);

  const currNumFiles = files.length;
  const prevNumFiles = usePrevious(currNumFiles);
  useEffect(() => {
    if (!fileListRef.current) return;
    if (currNumFiles > (prevNumFiles ?? 0)) {
      fileListRef.current.scrollLeft = fileListRef.current.scrollWidth;
    }
  }, [currNumFiles, files, prevNumFiles]);

  if (!props.enabled) return <div className='h-10'></div>;

  return (
    <>
      <div
        className={`w-full flex flex-col items-center justify-center gap-2 ${
          props.className ?? ''
        }`}
      >
        <div
          ref={containerRef}
          className={`${
            active
              ? files.length > 0
                ? 'w-160 h-50'
                : 'w-160 h-30'
              : 'w-50 h-10'
          } transition-size duration-300`}
        >
          <div
            className={`w-full h-full ${active ? 'flex' : 'hidden'} flex-col`}
          >
            <div className='w-full flex-grow relative'>
              <div
                ref={dropRef}
                className={`w-full h-full flex flex-col rounded-xl bg-dark-gray border 
                border-secondary ${props.ctxLabel ? 'rounded-bl-none' : ''} ${
                  hasIncompleteFiles ? 'rounded-tl-none' : ''
                }`}
              >
                {files.length > 0 && (
                  <div
                    ref={fileListRef}
                    className='w-full h-auto flex items-center px-2 pt-2.5 gap-2 overflow-x-auto'
                    style={{
                      scrollbarColor: '#8b9294 transparent',
                      scrollbarWidth: 'thin',
                    }}
                  >
                    {files.map((file) => (
                      <UGCFileAttachment
                        key={file.file.id}
                        file={file}
                        onDelete={() => fileman.deleteFile(file.file.id)}
                        onDownload={() => fileman.downloadFile(file.file.id)}
                      />
                    ))}
                  </div>
                )}
                <textarea
                  ref={ref}
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  onKeyDown={onKeyDown}
                  className={`w-full flex-grow bg-transparent resize-none outline-none
                   text-white text-sms caret-color-delete px-5 ${
                     files.length > 0 ? 'py-2' : 'py-4'
                   }`}
                  placeholder={props.placeholder}
                  disabled={isSubmitting}
                />
              </div>
              {props.ctxLabel && (
                <p
                  className='bg-lp-gray-003 text-icon-gray rounded px-2 py-1 
              absolute text-3xs bottom-0 left-0 transform-gpu translate-y-full 
              rounded-t-none'
                >
                  {props.ctxLabel}
                </p>
              )}
              {hasIncompleteFiles && (
                <p
                  className='bg-lp-gray-003 text-white rounded px-2 py-1 
              absolute text-3xs top-0 left-0 transform-gpu -translate-y-full 
              rounded-b-none'
                >
                  Some files are not ready to be used for AI.
                </p>
              )}
              <div className='flex items-center justify-center gap-2 absolute right-2 bottom-2'>
                {isSubmitting && props.enableThinkingHint && (
                  <CustomGameThinkingHint
                    containerClassName='!h-7.5 text-xs !border rounded-md !px-2'
                    loadingClassName='w-5 h-5'
                  />
                )}
                <label
                  className={`btn-secondary w-7.5 h-7.5 rounded-xl flex items-center 
                justify-center flex-shrink-0 bg-black border-0 cursor-pointer`}
                >
                  <AttachIcon />
                  <div className='hidden w-0 h-0'>
                    <FileInput uppy={fileman.uppy} inputName='files' />
                  </div>
                </label>
                {isSubmitting ? (
                  <button
                    type='button'
                    className={`btn-delete w-7.5 h-7.5 rounded-xl flex items-center 
                justify-center flex-shrink-0`}
                    onClick={props.onAbort}
                  >
                    <StopIcon />
                  </button>
                ) : (
                  <button
                    type='button'
                    className={`btn-delete w-7.5 h-7.5 rounded-xl flex items-center 
                justify-center flex-shrink-0`}
                    disabled={
                      isSubmitting || hasInProgressFiles || prompt.trim() === ''
                    }
                    onClick={submit}
                    title={
                      hasInProgressFiles
                        ? 'Wait for files to be ready'
                        : undefined
                    }
                  >
                    <ChatSendIcon />
                  </button>
                )}
              </div>
              <div
                className={`flex items-center justify-center text-sms 
              px-2 py-1 absolute bottom-1 left-1/2 transform -translate-x-1/2 
              rounded text-white transition-opacity ${
                message ? 'opacity-100' : 'opacity-0'
              } ${
                  message?.type === 'error'
                    ? 'bg-error'
                    : message?.type === 'success'
                    ? 'bg-lp-green-001'
                    : 'bg-secondary'
                }`}
              >
                {message?.detail}
              </div>
              <div
                className={`bg-[#FBB7071A] text-tertiary text-sms rounded-xl 
              px-10 py-2 absolute bottom-1 left-1/2 transform -translate-x-1/2 
              transition-opacity ${draging ? 'opacity-100' : 'opacity-0'}`}
              >
                Drop file here to upload
              </div>
              {props.accessory}
            </div>
            <div className='text-icon-gray text-3xs mt-1.5 text-center'>
              AI Editor can make mistakes. Please check your work.
            </div>
          </div>
          <button
            type='button'
            className={`w-full h-full rounded-xl bg-dark-gray border border-secondary 
        relative items-center justify-between p-3 ${
          !active ? 'flex' : 'hidden'
        }`}
            onClick={toggle}
          >
            <div className='flex items-center justify-center text-2xs text-icon-gray gap-1'>
              <AIIcon className='w-3.5 h-3.5 fill-current' />
              <p className='max-w-24 truncate'>
                {prompt ? prompt : 'AI Editor'}
              </p>
            </div>
            <p className='text-sms text-icon-gray'>{modSym}K</p>
          </button>
        </div>
      </div>
      {active && props.showOverlayWhenActive && (
        <div className='w-full h-full bg-black bg-opacity-50 absolute z-[1]'></div>
      )}
    </>
  );
}

export function UGCActivityPromptEditor(props: EditorProps) {
  return (
    <CustomGamePackPromptEditor
      {...props}
      showOverlayWhenActive
      className={`absolute z-5 bottom-4`}
    />
  );
}
