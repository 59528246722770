import { z } from 'zod';

export const charadesGameSchema = z
  .object({
    charadesCards: z
      .array(
        z
          .object({
            phrase1: z
              .object({
                text: z
                  .string()
                  .describe(
                    'The text of the phrase that the player should act out, keep this less than 30 characters'
                  ),
                category: z
                  .string()
                  .describe(
                    'The category of the phrase, keep this short like 10 characters. e.g. "Animal", "Drink", "Action", etc.'
                  ),
              })
              .strict()
              .describe('The first phrase that the player should act out'),
            phrase2: z
              .object({
                text: z
                  .string()
                  .describe(
                    'The text of the phrase that the player should act out, keep this less than 30 characters'
                  ),
                category: z
                  .string()
                  .describe(
                    'The category of the phrase, keep this short like 10 characters. e.g. "Animal", "Drink", "Action", etc.'
                  ),
              })
              .strict()
              .describe('The second phrase that the player should act out'),
            phrase3: z
              .object({
                text: z
                  .string()
                  .describe(
                    'The text of the phrase that the player should act out, keep this less than 30 characters'
                  ),
                category: z
                  .string()
                  .describe(
                    'The category of the phrase, keep this short like 10 characters. e.g. "Animal", "Drink", "Action", etc.'
                  ),
              })
              .strict()
              .describe('The third phrase that the player should act out'),
            phrase4: z
              .object({
                text: z
                  .string()
                  .describe(
                    'The text of the phrase that the player should act out, keep this less than 30 characters'
                  ),
                category: z
                  .string()
                  .describe(
                    'The category of the phrase, keep this short like 10 characters. e.g. "Animal", "Drink", "Action", etc.'
                  ),
              })
              .strict()
              .describe('The fourth phrase that the player should act out'),
          })
          .strict()
      )
      .describe('Charades cards used in the game'),
  })
  .strict()
  .describe('CharadesGame represents the game of Charades');
