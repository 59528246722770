import { z } from 'zod';

export const drawingBlankGameSchema = z
  .object({
    prompts: z
      .array(z.string())
      .describe('A list of prompts that the player should draw'),
  })
  .strict()
  .describe('DrawingBlankGame represents the game of Drawing a Blank');
