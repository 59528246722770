import { FileInput } from '@uppy/react';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import {
  type IcebreakerBlock,
  type IcebreakerCard,
  type IcebreakerOption,
} from '@lp-lib/game';
import { charadesGameSchema } from '@lp-lib/shared-schema/src/ai/functions/zod/charadesGame';
import { type CharadesGameVariable } from '@lp-lib/shared-schema/src/ai/variables/types/charadesGame';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { apiService } from '../../../../services/api-service';
import { downloadFile, uuidv4 } from '../../../../utils/common';
import { createCSVFile, csvToArray } from '../../../../utils/csv';
import { MenuItem } from '../../../common/ActionMenu';
import { useCancelConfirmModalStateRoot } from '../../../ConfirmCancelModalContext';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import {
  useCSVUploader,
  useEditor,
} from '../../Blocks/Common/Editor/EditorUtilities';
import { IcebreakerUtils } from '../../Blocks/Icebreaker/utils';
import { UGCActivityPromptEditor } from '../CustomGamePackPromptEditor';
import { IcebreakerActivityEditorLayout } from '../design/Icebreaker/ActivityEditorLayout';
import { IcebreakerDiffTableModal } from '../design/Icebreaker/Diff';
import { OrdinalCarousel } from '../design/OrdinalCarousel';
import {
  UGCDiffAcceptButton,
  UGCDiffRejectButton,
} from '../design/UGCDiffModal';
import { UGCEditorToolMenu } from '../design/UGCEditorToolMenu';
import {
  type ActivityEditorProps,
  type ActivityGenAIRunRequest,
} from '../types';
import { generateBlock, log, UGCUtils, useAbortableBlockGenAI } from '../utils';

const parseSchema = charadesGameSchema.parse;
type Schema = ReturnType<typeof parseSchema>;

function transform(data: ReturnType<typeof parseSchema>): IcebreakerCard[] {
  return data.charadesCards.map((card) => {
    return {
      id: uuidv4(),
      texts: [],
      options: [
        { text: `${card.phrase1.category} - ${card.phrase1.text}` },
        { text: `${card.phrase2.category} - ${card.phrase2.text}` },
        { text: `${card.phrase3.category} - ${card.phrase3.text}` },
        { text: `${card.phrase4.category} - ${card.phrase4.text}` },
      ],
      hiddenToAudience: true,
    };
  });
}

async function runAI(
  packId: string,
  block: IcebreakerBlock,
  prompt: string,
  signal?: AbortSignal | null
): Promise<Schema> {
  const existingCards = block.fields.cards.map<
    ArrayElement<Schema['charadesCards']>
  >((card) => {
    const options = [...card.options];
    if (options.length < 4) {
      options.push(...new Array(4 - options.length).fill({ text: '' }));
    }
    const phrases = options.map((option) => {
      const chunks = option.text.split('-');
      if (chunks.length === 1) {
        return {
          category: '',
          text: chunks[0].trim(),
        };
      } else {
        return {
          category: chunks[0].trim(),
          text: chunks.slice(1).join('-').trim(),
        };
      }
    });

    return {
      phrase1: phrases[0],
      phrase2: phrases[1],
      phrase3: phrases[2],
      phrase4: phrases[3],
    };
  });
  const variables: CharadesGameVariable = {
    existingCharadesCards: JSON.stringify(existingCards),
  };
  const resp = await generateBlock(
    packId,
    {
      blockId: block.id,
      userPrompt: prompt,
      variables,
    },
    { signal }
  );
  log.info('generate block response', { data: resp.data });

  for (const step of resp.data.runSteps) {
    if (UGCUtils.VaildRunStep(step)) {
      const fn = step.stepDetails.tool_calls[0].function;
      const output = UGCUtils.ParseFunctionCall(packId, block, fn, parseSchema);
      return output;
    }
  }
  throw new Error('Something went wrong, pelase try again');
}

export async function charadesGenAIAction(
  req: ActivityGenAIRunRequest<IcebreakerBlock>
) {
  const { packId, block, prompt, signal } = req;
  const data = await runAI(packId, block, prompt, signal);
  await apiService.block.updateFieldV2(block, 'cards', transform(data));
}

function CardOptionField(props: {
  index: number;
  option: IcebreakerOption;
  onUpdateCardOption: (updates: Partial<IcebreakerOption>) => void;
  placeholder?: string;
  className?: string;
}) {
  const { index, option, onUpdateCardOption } = props;
  const [text, setText] = useState(option.text);
  const background = useMemo(
    () => IcebreakerUtils.GetCardOptionBackgroundStyle(index),
    [index]
  );
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const baseHeight = useRef<number | null>(null);

  // a trick to adjust the number of rows of textarea based on its content, this
  // can keep the textarea vertically centered
  const adjustRows = useLiveCallback(() => {
    if (!ref.current) return;
    if (!baseHeight.current) {
      baseHeight.current = ref.current.clientHeight;
    }
    ref.current.rows = 1;
    const scrollHeight = ref.current.scrollHeight;
    ref.current.rows = scrollHeight / baseHeight.current;
  });

  useEffect(() => adjustRows(), [adjustRows]);

  return (
    <div
      style={{
        background,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
      }}
      className={`relative p-4 w-[calc(50%-0.5rem)] h-[calc(50%-0.5rem)] flex-none
        rounded-3.75xl flex justify-center items-center text-center
         transition-opacity duration-200
      `}
    >
      <textarea
        className={`w-full text-base 2xl:text-xl outline-none 
          appearance-none bg-transparent text-white resize-none font-bold
      px-1 text-center placeholder-white placeholder-opacity-50 ${
        props.className ?? ''
      }`}
        ref={ref}
        rows={1}
        placeholder={props.placeholder}
        value={text}
        onChange={(e) => {
          setText(e.target.value.replace(/\n/g, ''));
          adjustRows();
        }}
        onBlur={() => {
          if (text !== option.text) {
            onUpdateCardOption({ text });
          }
        }}
        maxLength={50}
      />
    </div>
  );
}

function CardEditor(props: {
  card: IcebreakerCard;
  onUpdateCardOption: (
    id: string,
    index: number,
    updates: Partial<IcebreakerOption>
  ) => void;
}) {
  const { card, onUpdateCardOption } = props;
  return (
    <div className='w-4/5 h-4/5 flex items-center justify-center gap-4 flex-wrap'>
      {' '}
      {card.options.map((option, index) => {
        return (
          <CardOptionField
            key={index}
            index={index}
            option={option}
            onUpdateCardOption={(updates) =>
              onUpdateCardOption(card.id, index, updates)
            }
            placeholder={`Category - Word #${index + 1}`}
          />
        );
      })}
    </div>
  );
}

const TABLE_HEADERS = ['Phrase 1', 'Phrase 2', 'Phrase 3', 'Phrase 4'];

export function CharadesActivityEditor(
  props: ActivityEditorProps<IcebreakerBlock>
): JSX.Element | null {
  const { packId, block, asset, root } = props;
  const { updateField } = useEditor(props);
  const [modal, triggerModal] = useCancelConfirmModalStateRoot();

  const cards = block.fields.cards;
  const [selected, setSelected] = useState<{
    id: string;
    index: number;
  } | null>(
    cards.length
      ? {
          id: cards[0].id,
          index: 0,
        }
      : null
  );
  const selectedCard = selected ? cards[selected.index] : null;
  const onChange = async (cards: IcebreakerCard[]) => {
    await updateField('cards', cards);
  };
  const handleAddCard = async () => {
    const length = cards.length;
    const newCard = {
      id: uuidv4(),
      texts: [],
      options: new Array(4).fill({ text: '' }),
      hiddenToAudience: true,
    };
    await onChange([...cards, newCard]);
    setSelected({ id: newCard.id, index: length });
  };

  const handleDeleteCard = async (cardId: string) => {
    const idx = cards.findIndex((c) => c.id === cardId);
    if (idx === -1) return;
    if (selected?.id === cardId) {
      setSelected(null);
    }
    await onChange(cards.filter((c) => c.id !== cardId));
    if (selected?.id === cardId) {
      if (cards[idx + 1]) {
        setSelected({ id: cards[idx + 1].id, index: idx + 1 });
      } else if (cards[idx - 1]) {
        setSelected({ id: cards[idx - 1].id, index: idx - 1 });
      } else if (cards[0]) {
        setSelected({ id: cards[0].id, index: 0 });
      } else {
        setSelected(null);
      }
    }
  };

  const handleUpdateCardOption = useLiveCallback(
    (id: string, index: number, updates: Partial<IcebreakerOption>) => {
      const card = cards.find((c) => c.id === id);
      if (!card) return;
      const newOption = { ...card.options[index], ...updates };
      const newOptions = card.options.map((o, i) =>
        i === index ? newOption : o
      );
      const newCard = {
        ...card,
        options: newOptions,
      };
      const newCards = cards.map((c) => (c.id === id ? newCard : c));
      onChange(newCards);
    }
  );

  const { generate, abort, state, error } = useAbortableBlockGenAI(
    async (prompt: string, signal: AbortSignal) => {
      const originalPrompt = prompt;
      let optimizedPrompt = prompt;

      try {
        const optimizePromptResp = await apiService.gamePack.optimizeUGCPrompt(
          packId,
          {
            prompt,
          }
        );
        optimizedPrompt = optimizePromptResp.data.prompt;
      } catch (error) {
        log.error('Failed to optimize prompt', error);
      }

      props.analytics.trackCustomGameAIPrompt({
        packId: packId,
        blockId: block.id,
        blockType: block.type,
        prompt: originalPrompt,
        optimizedPrompt:
          originalPrompt === optimizedPrompt ? 'N/A' : optimizedPrompt,
      });

      const data = await runAI(packId, block, optimizedPrompt, signal);
      const cards = transform(data);

      await triggerModal({
        kind: 'custom',
        element: (p) => (
          <IcebreakerDiffTableModal
            headers={TABLE_HEADERS}
            oldCards={block.fields.cards}
            newCards={cards}
            footer={
              <footer className='flex justify-center items-center gap-5'>
                <UGCDiffRejectButton onClick={p.internalOnCancel} />
                <UGCDiffAcceptButton
                  onClick={async () => {
                    await updateField('cards', cards);
                    p.internalOnConfirm();
                    if (cards.length > 0) {
                      setSelected({ id: cards[0].id, index: 0 });
                    } else {
                      setSelected(null);
                    }
                  }}
                />
              </footer>
            }
          />
        ),
      });

      return true;
    }
  );

  const items = useMemo(() => {
    return cards.map((card) => ({
      id: card.id,
      label: card.options.length ? card.options[0].text : undefined,
    }));
  }, [cards]);

  const handleSeeAll = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <IcebreakerDiffTableModal
          headers={TABLE_HEADERS}
          oldCards={block.fields.cards}
          newCards={block.fields.cards}
          onClick={(index) => {
            setSelected({ id: cards[index].id, index });
            p.internalOnCancel();
          }}
          footer={
            <footer className='flex justify-center items-center gap-5'>
              <button
                type='button'
                onClick={p.internalOnCancel}
                className={`btn-primary w-26 h-9`}
              >
                <p>Close</p>
              </button>
            </footer>
          }
        />
      ),
    });
  };

  const uppy = useCSVUploader(async (content) => {
    const csv = csvToArray(content.toString());
    if (csv.length <= 1) return;
    const rows = csv.splice(1);
    const newCards = rows.map<IcebreakerCard>((row) => {
      const options = [
        { text: row[0] ?? '' },
        { text: row[1] ?? '' },
        { text: row[2] ?? '' },
        { text: row[3] ?? '' },
      ];
      return {
        id: uuidv4(),
        texts: [],
        options,
        hiddenToAudience: true,
      };
    });
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <IcebreakerDiffTableModal
          headers={TABLE_HEADERS}
          oldCards={block.fields.cards}
          newCards={newCards}
          footer={
            <footer className='flex justify-center items-center gap-5'>
              <UGCDiffRejectButton onClick={p.internalOnCancel} />
              <UGCDiffAcceptButton
                onClick={async () => {
                  await updateField('cards', newCards);
                  p.internalOnConfirm();
                  if (cards.length > 0) {
                    setSelected({ id: newCards[0].id, index: 0 });
                  } else {
                    setSelected(null);
                  }
                }}
              />
            </footer>
          }
        />
      ),
    });
  });

  const handleCSVDownload = () => {
    const data = [TABLE_HEADERS];
    for (const card of block.fields.cards) {
      const row = card.options.flatMap((option) => [option.text]);
      data.push(row);
    }
    const url = createCSVFile(data);
    downloadFile(url, `charades-${block.id}.csv`);
  };

  return (
    <Fragment>
      {modal && root?.current && createPortal(modal, root.current)}
      <div
        className={`w-full h-full flex-col ${
          modal ? 'hidden' : 'flex'
        } gap-0.5`}
      >
        <div className='w-full absolute flex flex-col gap-2'>
          <div className='w-full flex text-white items-center gap-1'>
            <div className='text-sms font-bold flex-shrink-0 text-center'>
              <p>Preview Cards ({cards.length})</p>
            </div>
            <OrdinalCarousel
              items={items}
              onSelect={(id, index) => setSelected({ id, index })}
              selected={selected ?? undefined}
              contextMenu={(id, index) => (
                <button
                  type='button'
                  className='btn bg-secondary text-red-002 
              text-opacity-80 hover:text-opacity-100 w-20 h-8 text-sms'
                  onClick={() => handleDeleteCard(id)}
                >
                  Delete #{index + 1}
                </button>
              )}
            />
            <button
              type='button'
              className='btn-secondary h-8 flex-shrink-0 font-bold 
          flex items-center justify-center px-4 rounded-lg'
              onClick={handleAddCard}
            >
              Add Card
            </button>
            <UGCEditorToolMenu>
              {(hide) => (
                <Fragment>
                  <MenuItem
                    text='See All'
                    onClick={() => {
                      handleSeeAll();
                      hide();
                    }}
                  />
                  <MenuItem
                    text={
                      <label className='w-full h-full cursor-pointer flex items-center justify-start'>
                        Upload CSV
                        <div className='hidden'>
                          <FileInput uppy={uppy} inputName={'files'} />
                        </div>
                      </label>
                    }
                    onClick={() => {
                      setTimeout(() => hide(), 1000);
                    }}
                  />
                  <MenuItem
                    text='Download CSV'
                    onClick={() => {
                      handleCSVDownload();
                      hide();
                    }}
                  />
                </Fragment>
              )}
            </UGCEditorToolMenu>
          </div>
          <IcebreakerActivityEditorLayout
            {...props}
            hint='Click the card you want to act out. Announce the topic and act out the charade using no words, only actions'
            accessory={
              selectedCard ? (
                <button
                  type='button'
                  className='btn-secondary w-7.5 h-7.5 rounded-lg absolute 
              right-4 top-4 flex items-center justify-center hover:text-red-002'
                  onClick={() => handleDeleteCard(selectedCard.id)}
                >
                  <DeleteIcon />
                </button>
              ) : null
            }
          >
            {selectedCard ? (
              <CardEditor
                key={selectedCard.id}
                card={selectedCard}
                onUpdateCardOption={handleUpdateCardOption}
              />
            ) : (
              <div className='text-white'>Select a card first</div>
            )}
          </IcebreakerActivityEditorLayout>
        </div>
        <UGCActivityPromptEditor
          enabled={!!props.enableAI}
          onSubmit={generate}
          onAbort={abort}
          isSubmitting={state.isRunning}
          ctxLabel={`Editing ${asset.primaryText}`}
          error={error}
          enableThinkingHint
          placeholder='Generate 50 Charades cards of ...'
        />
      </div>
    </Fragment>
  );
}
