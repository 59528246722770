import { type ReactNode, useState } from 'react';

export function SimpleTextEditor(props: {
  label: ReactNode;
  value?: string;
  onBlur: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  textClassName?: string;
  containerClassName?: string;
}) {
  const [internalValue, setInternalValue] = useState(props.value ?? '');

  return (
    <div
      className={`w-full h-full flex flex-col gap-2 px-6 py-4.5
         bg-modal rounded-xl border border-secondary ${
           props.containerClassName ?? ''
         }`}
    >
      <div className='text-white'>{props.label}</div>
      <textarea
        className={`field m-0 w-full h-full resize-none py-2 ${
          props.textClassName ?? ''
        }`}
        value={internalValue}
        onChange={(e) => setInternalValue(e.target.value)}
        onBlur={() => props.onBlur(internalValue)}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
    </div>
  );
}
