import { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { Menu } from '../../../common/ActionMenu';
import { OptionsIcon } from '../../../icons/OptionsIcon';

export function UGCEditorToolMenu(props: {
  children: (hide: () => void) => JSX.Element;
}) {
  const [controlledVisibility, setControlledVisibility] = useState(false);
  const handleVisibleChange = useLiveCallback((visible: boolean) => {
    setControlledVisibility(visible);
  });

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'click',
      placement: 'bottom-end',
      interactive: true,
      visible: controlledVisibility,
      onVisibleChange: handleVisibleChange,
    });
  const hide = useLiveCallback(() => handleVisibleChange(false));

  return (
    <div className='relative flex items-center'>
      <button
        className='btn-secondary w-7.5 h-7.5 rounded-lg flex items-center justify-center'
        ref={setTriggerRef}
        type='button'
      >
        <OptionsIcon className='w-3.5 h-3.5 fill-current' />
      </button>
      {visible &&
        createPortal(
          <Menu ref={setTooltipRef} {...getTooltipProps()}>
            {props.children(hide)}
          </Menu>,
          document.body
        )}
    </div>
  );
}
