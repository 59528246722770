import { z } from 'zod';

export const tabooGameSchema = z
  .object({
    tabooCards: z
      .array(
        z
          .object({
            guessWord: z
              .string()
              .describe('The word that the player should guess'),
            tabooWords: z
              .array(z.string())
              .describe('5 words that the player should avoid using'),
          })
          .strict()
          .describe('TabooCard represents a single card in the game of Taboo')
      )
      .describe('Taboo cards used in the game'),
  })
  .strict()
  .describe('TabooGame represents the game of Taboo');
